<template>
  <r-box class="addsearch">
    <r-section
      :label="$t('addSearch.title')"
    />
    <v-form
      ref="form"
      v-model="valid"
      class="mt-5"
      @submit.prevent="onClickSave"
    >
      <v-row>
        <v-col>
          <r-text-field
            class="addsearch--name"
            :label="$t('addSearch.fields.name.label')"
            :placeholder="$t('addSearch.fields.name.placeholder')"
            :value="name"
            :rules="nameRules"
            style="width:100%"
            :validate-on-blur="true"
            @change="(value) => onChangeSearchName(value)"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          sm="12"
          class="py-2 mb-5"
        >
          <v-divider />
        </v-col>
      </v-row>
      <v-row
        v-for="(link, index) in links"
        :key="`addsearch-link-${index}-${links.length}`"
        class="mx-0"
      >
        <v-col
          sm="12"
          class="px-0 pt-0"
        >
          <span class="addsearch--link-details">{{ $t('addSearch.linkDetails') }}</span>
        </v-col>
        <v-col
          sm="12"
          class="addsearch--link px-4"
        >
          <div class="d-flex align-content-start justify-end">
            <v-icon
              v-if="links.length > 1"
              class="addsearch--delete"
              @click="onClickRemove(index)"
            >
              delete
            </v-icon>
          </div>
          <r-text-field
            class="addsearch--listingUrl"
            :label="$t('addSearch.fields.linkUrl.label')"
            :placeholder="$t('addSearch.fields.linkUrl.placeholder')"
            :value="link.listingUrl"
            :rules="linkItemRules"
            style="width:100%"
            :validate-on-blur="true"
            :error-message="link.serverValidationError"
            @change="(value) => onListingUrlChange(index, value)"
          />
          <r-text-field
            class="addsearch--linkname mt-2"
            :label="$t('addSearch.fields.linkName.label')"
            :placeholder="$t('addSearch.fields.linkName.placeholder')"
            :value="link.name"
            :rules="nameRules"
            style="width:100%"
            :validate-on-blur="true"
            @change="(value) => onListingNameChange(index, value)"
          />
        </v-col>

        <v-col
          sm="12"
          class="px-0 my-5"
        >
          <v-divider />
        </v-col>
      </v-row>
      <v-row class="mt-2">
        <v-col>
          <r-button
            class="addsearch--addmore"
            icon="mdi-plus"
            :label="$t('global.actions.addMore')"
            :outlined="true"
            :loading="fetchingValidateListingUrls"
            :disabled="!allLinksValid || !canAddMoreItems"
            @click="onClickAddMore"
          />
        </v-col>
      </v-row>
      <v-row class="mt-6">
        <v-col>
          <label>{{ $t('resources.project') }}</label>
          <project-select
            class="addsearch--selectproject mt-1"
            @change="onChangeProjectId"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col sm="12">
          <r-error-message
            class="addsearch--errormessage"
            :errors="[fetchCreateSearchError]"
          />
        </v-col>
        <v-col class="d-flex justify-end align-center">
          <r-button
            class="mr-2"
            :label="$t('global.actions.cancel')"
            :outlined="true"
            @click="onClickCancel"
          />
          <r-button
            :label="$t('global.actions.save')"
            class="addsearch--save"
            :disabled="!isFormValid || fetchingValidateListingUrls"
            :loading="fetchingCreateSearch"
            @click="onClickSave"
          />
        </v-col>
      </v-row>
    </v-form>
  </r-box>
</template>
<script>
import RBox from '@/components/library/molecules/RBox'
import RButton from '@/components/library/atoms/RButton'
import RSection from '@/components/library/molecules/RSection'
import RTextField from '@/components/library/molecules/RTextField'
import ProjectSelect from '@/components/app/data/ProjectSelect'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import { MAX_LINKS_NUMBER_PER_SEARCH } from '@/utils/constants'
import { mapActions, mapGetters, mapState } from 'vuex'
import Vue from 'vue'
import isValidUrl from '@/utils/isValidUrl'
import { track } from '@/tracking'

const includeHttps = (url) => `https://${url.replace(/^https?:\/\//, '')}`

const EMPTY_LINK_ITEM = { name: '', listingUrl: '' }

export default {
  name: 'AddSearch',
  components: {
    RBox,
    RButton,
    RSection,
    RTextField,
    ProjectSelect,
    RErrorMessage,
  },
  data() {
    return {
      valid: false,
      name: '',
      links: [EMPTY_LINK_ITEM],
      projectId: '',
      nameRules: [
        (value) => !!value || this.$i18n.t('global.validations.messages.required'),
      ],
      linkItemRules: [
        (value) => !!value || this.$i18n.t('global.validations.messages.required'),
        (value) => isValidUrl(value) || this.$i18n.t('global.validations.messages.invalidURL'),
      ],
    }
  },
  computed: {
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('urls', [
      'fetchingValidateListingUrls',
      'validListingUrls',
      'validateListingUrlsErrors',
    ]),
    ...mapState('search', [
      'fetchingCreateSearch',
      'fetchCreateSearchError',
    ]),
    allLinksValid() {
      const validResult = this.links
        .map((link) => {
          const isValidListingUrl = isValidUrl(link.listingUrl)
          const hasName = link.name.trim().length

          return isValidListingUrl && hasName
        })

      return validResult.every((result) => result)
    },
    isFormValid() {
      if (this.$refs.form) {
        return this.allLinksValid && this.$refs.form.validate()
      }

      return this.allLinksValid
    },
    itemsToSave() {
      const items = [...this.links]

      if (items.length > 1 && !items.at(-1).listingUrl) {
        items.pop()
      }

      return items
    },
    canAddMoreItems() {
      return this.links.length < MAX_LINKS_NUMBER_PER_SEARCH
    },
  },
  mounted() {
    this.$data.projectId = this.selectedProjectId
  },
  methods: {
    ...mapActions('urls', [
      'validateListingUrls',
    ]),
    ...mapActions('search', [
      'createSearch',
      'clearErrors',
    ]),
    onChangeSearchName({ value: newName }) {
      this.name = newName
    },
    resetServerSideValidation() {
      this.links = this.links.map((link) => ({
        ...link,
        listingUrl: link.listingUrl ? includeHttps(link.listingUrl) : '',
        serverValidationError: '',
      }))
    },
    async runServerSideValidation() {
      this.resetServerSideValidation()

      const listingUrls = this.itemsToSave.map(({ listingUrl }) => listingUrl)

      await this.validateListingUrls({ urls: listingUrls })

      if (this.validateListingUrlsErrors.length) {
        this.validateListingUrlsErrors.forEach(({ urlIndex, message }) => {
          this.links[urlIndex].serverValidationError = message
        })
      } else {
        this.links = this.validListingUrls.map((listingUrl, index) => ({
          ...this.links[index],
          listingUrl,
        }))
      }
    },
    async onClickAddMore(e) {
      e.preventDefault()

      await this.runServerSideValidation()

      if (!this.validateListingUrlsErrors.length) {
        this.links = this.links.concat(EMPTY_LINK_ITEM)
      }
    },
    onListingUrlChange(index, { value: listingUrl }) {
      const link = this.links[index]
      const updatedLink = {
        ...link,
        listingUrl,
      }

      Vue.set(this.links, index, updatedLink)
    },
    onListingNameChange(index, { value: name }) {
      const link = this.links[index]
      const updatedLink = {
        ...link,
        name,
      }

      Vue.set(this.links, index, updatedLink)
    },
    onClickRemove(index) {
      this.links = this.links.filter((_, i) => i !== index)
    },
    onChangeProjectId({ value }) {
      this.projectId = value
    },
    onClickCancel(e) {
      e.preventDefault()

      this.clearErrors({ fetchCreateSearchError: '' })

      this.$router.push('/data')
    },
    async onClickSave() {
      await this.runServerSideValidation()
      if (this.fetchingValidateListingUrls.length) {
        return
      }

      if (!this.$refs.form.validate()) {
        return
      }

      const createPayload = {
        name: this.name,
        projectId: this.projectId,
        links: this.links,
      }

      await this.createSearch(createPayload)

      if (!this.fetchCreateSearchError) {
        track('reviews add search page - save data clicked')
        this.$router.push('/data')
      }
    },
  },
}
</script>

<style scoped>
.addsearch {
  width: 640px;
  min-height: 60%;
  margin: 0 auto;
}
.addsearch--link-details {
  font-size: 14px;
  font-weight: 700;
  color: var(--r-dark-grey);
}
.addsearch--link {
  background-color: var(--soft-grey-color);
  border-radius: 3px;
}
/deep/ .v-text-field.v-input--dense.v-text-field--outlined > .v-input__control > .v-input__slot {
  background-color: white;
}
</style>
