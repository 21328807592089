var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('r-box', {
    staticClass: "addsearch"
  }, [_c('r-section', {
    attrs: {
      "label": _vm.$t('addSearch.title')
    }
  }), _c('v-form', {
    ref: "form",
    staticClass: "mt-5",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onClickSave.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function ($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_c('v-col', [_c('r-text-field', {
    staticClass: "addsearch--name",
    staticStyle: {
      "width": "100%"
    },
    attrs: {
      "label": _vm.$t('addSearch.fields.name.label'),
      "placeholder": _vm.$t('addSearch.fields.name.placeholder'),
      "value": _vm.name,
      "rules": _vm.nameRules,
      "validate-on-blur": true
    },
    on: {
      "change": function (value) {
        return _vm.onChangeSearchName(value);
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-2 mb-5",
    attrs: {
      "sm": "12"
    }
  }, [_c('v-divider')], 1)], 1), _vm._l(_vm.links, function (link, index) {
    return _c('v-row', {
      key: `addsearch-link-${index}-${_vm.links.length}`,
      staticClass: "mx-0"
    }, [_c('v-col', {
      staticClass: "px-0 pt-0",
      attrs: {
        "sm": "12"
      }
    }, [_c('span', {
      staticClass: "addsearch--link-details"
    }, [_vm._v(_vm._s(_vm.$t('addSearch.linkDetails')))])]), _c('v-col', {
      staticClass: "addsearch--link px-4",
      attrs: {
        "sm": "12"
      }
    }, [_c('div', {
      staticClass: "d-flex align-content-start justify-end"
    }, [_vm.links.length > 1 ? _c('v-icon', {
      staticClass: "addsearch--delete",
      on: {
        "click": function ($event) {
          return _vm.onClickRemove(index);
        }
      }
    }, [_vm._v(" delete ")]) : _vm._e()], 1), _c('r-text-field', {
      staticClass: "addsearch--listingUrl",
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "label": _vm.$t('addSearch.fields.linkUrl.label'),
        "placeholder": _vm.$t('addSearch.fields.linkUrl.placeholder'),
        "value": link.listingUrl,
        "rules": _vm.linkItemRules,
        "validate-on-blur": true,
        "error-message": link.serverValidationError
      },
      on: {
        "change": function (value) {
          return _vm.onListingUrlChange(index, value);
        }
      }
    }), _c('r-text-field', {
      staticClass: "addsearch--linkname mt-2",
      staticStyle: {
        "width": "100%"
      },
      attrs: {
        "label": _vm.$t('addSearch.fields.linkName.label'),
        "placeholder": _vm.$t('addSearch.fields.linkName.placeholder'),
        "value": link.name,
        "rules": _vm.nameRules,
        "validate-on-blur": true
      },
      on: {
        "change": function (value) {
          return _vm.onListingNameChange(index, value);
        }
      }
    })], 1), _c('v-col', {
      staticClass: "px-0 my-5",
      attrs: {
        "sm": "12"
      }
    }, [_c('v-divider')], 1)], 1);
  }), _c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', [_c('r-button', {
    staticClass: "addsearch--addmore",
    attrs: {
      "icon": "mdi-plus",
      "label": _vm.$t('global.actions.addMore'),
      "outlined": true,
      "loading": _vm.fetchingValidateListingUrls,
      "disabled": !_vm.allLinksValid || !_vm.canAddMoreItems
    },
    on: {
      "click": _vm.onClickAddMore
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', [_c('label', [_vm._v(_vm._s(_vm.$t('resources.project')))]), _c('project-select', {
    staticClass: "addsearch--selectproject mt-1",
    on: {
      "change": _vm.onChangeProjectId
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "sm": "12"
    }
  }, [_c('r-error-message', {
    staticClass: "addsearch--errormessage",
    attrs: {
      "errors": [_vm.fetchCreateSearchError]
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex justify-end align-center"
  }, [_c('r-button', {
    staticClass: "mr-2",
    attrs: {
      "label": _vm.$t('global.actions.cancel'),
      "outlined": true
    },
    on: {
      "click": _vm.onClickCancel
    }
  }), _c('r-button', {
    staticClass: "addsearch--save",
    attrs: {
      "label": _vm.$t('global.actions.save'),
      "disabled": !_vm.isFormValid || _vm.fetchingValidateListingUrls,
      "loading": _vm.fetchingCreateSearch
    },
    on: {
      "click": _vm.onClickSave
    }
  })], 1)], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }